const React = require('react');
const { RecommendationsList, RecommendationsListDouble } = require('@recommendations-fe/list');
const { RecommendationsCardHorizontalTitleFirst } = require('@recommendations-fe/card');
const { shape } = require('prop-types');
const RecommendationsIconFactory = require('../../recommendations/icon-factory');
const jsonToProps = require('../../recommendations/json-to-props');

const namespace = 'ui-seller-data-recos';

const Recommendations = props => {
  const { recommendationsContext, globalContext } = props;

  return (
    <div className={`${namespace}__container`}>
      <RecommendationsList
        ListComponent={RecommendationsListDouble}
        listType="double"
        Card={RecommendationsCardHorizontalTitleFirst}
        IconFactory={RecommendationsIconFactory}
        className="list-seller"
        {...jsonToProps(props, recommendationsContext, globalContext)}
      />
    </div>
  );
};

Recommendations.propTypes = {
  recommendationsContext: shape({}),
  globalContext: shape({}),
};

Recommendations.defaultProps = {
  recommendationsContext: null,
  globalContext: null,
};

module.exports = Recommendations;
